const apiPath = {
  users: {
    index: 'users',
    login: 'users/login',
    detail: 'users/:userId',
    adminSettings: 'users/admin-settings',
    uploadBrief: 'users/upload-brief',
    brief: 'users/brief'
  },
  groups: {
    index: 'groups',
    orderProviders: 'groups/order-providers',
    detail: 'groups/:groupId'
  },
  orders: {
    index: 'orders',
    detail: 'orders/:orderId',
    option: 'orders/:orderId/options',
    document: 'orders/:orderId/document',
    bundle: 'orders/:orderId/bundle',
    attachment: 'orders/:orderId/attachment/:docId',
    analytics: 'orders/analytics',
    dashboardAnalytics: 'orders/dashboard-analytics',
    collection: 'orders/collection'
  },
  addresses: {
    index: 'addresses',
    import: 'addresses/import',
    truncate: 'addresses/truncate'
  },
  senders: {
    index: 'senders',
    detail: 'senders/:senderId'
  },
  server: {
    status: 'server/status'
  },
  upocs: {
    index: 'upocs',
    import: 'upocs/import'
  }
}

export default apiPath
