import ApiService from '../utils/api.service'
import apiPath from '../utils/api.path'

const initialAddresses = () => ({
  data: null,
  loading: false,
  pageNum: 1,
  pageLen: 10
})

const address = {
  namespaced: true,
  state: {
    addresses: initialAddresses()
  },
  mutations: {
    SET_VALUE (state, { name, payload }) {
      state[name] = payload
    },
    UPDATE_PROPERTY (state, { name, payload }) {
      state[name] = { ...state[name], ...payload }
    }
  },
  actions: {
    async setPageNum ({ commit }, pageNum) {
      commit ('UPDATE_PROPERTY', { name: 'addresses', payload: { pageNum } })
    },
    async fetchAddresses ({ commit, state }, params={}) {
      return new Promise((resolve, reject) => {
        commit('UPDATE_PROPERTY', { name: 'addresses', payload: { loading: true } })
        ApiService.query(apiPath.addresses.index, {
          params: {
            ...params,
            page_num: state.addresses.pageNum,
            page_len: state.addresses.pageLen
          }
        })
          .then(({ data }) => {
            commit('UPDATE_PROPERTY', { name: 'addresses', payload: { loading: false, data: data } })
            resolve(data)
          })
          .catch((error) => {
            commit('UPDATE_PROPERTY', { name: 'addresses', payload: { loading: false } })
            reject(error)
          })
      })
    },
    async importCSV (store, formData) {
      return new Promise((resolve, reject) => {
        ApiService.post(apiPath.addresses.import, formData)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async truncate() {
      return new Promise((resolve, reject) => {
        ApiService.post(apiPath.addresses.truncate)
          .then(({ data }) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  },
  getters: {
  }
}

export default address
